import React, { useState, useEffect } from "react";
import "./AnalyticsBox.css";
import analyticsData from "./analyticsData";
import { getAnalyticsData } from "../../utils/apicalls";

function AnalyticsBox({
  selectedTimeStamp,
  cityName,
  isAnalyticsLayer,
  setIsAnalyticsLayer,
}) {
  const [analyticsStats, setAnalyticsStats] = useState(null);
  const [boxTitle, setBoxTitle] = useState("");

  useEffect(() => {
    const fetchAnalyticsDetails = async (
      cityName,
      selectedTimeStamp,
      jsonData
    ) => {
      try {
        const jsonData1 = await getAnalyticsData(
          selectedTimeStamp,
          cityName,
          jsonData
        );
        console.log(jsonData1, "anal data");
        setAnalyticsStats(jsonData1[0]);
        setBoxTitle(jsonData1[1]);
      } catch (error) {
        console.log(error);
        // setLoading(false);
      }
    };
    const jsonData = analyticsData();
    fetchAnalyticsDetails(cityName, selectedTimeStamp, jsonData);
  }, [cityName, selectedTimeStamp]);
  return (
    <>
      {analyticsStats && (
        <div
          className="analytics-box"
          onClick={() => setIsAnalyticsLayer(!isAnalyticsLayer)}
        >
          <div
            className={`analytics-box-heading ${
              isAnalyticsLayer ? "change-color" : ""
            }`}
          >
            <span className="material-symbols-outlined">analytics</span>
            <h5> Analytics</h5>
          </div>
          <div>
            <div className="analytics-details-header">
              <p>{boxTitle}</p>
              <h2 style={{ fontSize: "2em" }}>{analyticsStats.active_fires}</h2>
              <p
                className={`${
                  analyticsStats.downOrUp == "up"
                    ? "ab-status"
                    : "ab-status-green"
                }`}
              >
                6 ↓
              </p>
            </div>
            {analyticsStats.table_details.map((e, index) => (
              <div className="analytics-details" key={index}>
                <div>
                  <p>{e.time}</p>
                  <h2>{e.area}</h2>
                  <p
                    className={`${
                      e.downOrUp == "up" ? "ab-status" : "ab-status-green"
                    }`}
                  >
                    {e.stats}{" "}
                    {e.downOrUp == "up" ? (
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "0.8em" }}
                      >
                        trending_up
                      </span>
                    ) : (
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "0.8em" }}
                      >
                        trending_down
                      </span>
                    )}
                  </p>
                </div>
                <div>
                  <h3>{e.active}</h3>
                  <p>
                    {e.downOrUp} by {e.active}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="analytics-details">
          <div onClick={() => setOpenStatsBox(false)} className="ab-close-btn">
            <span>close</span>
          </div>
        </div> */}
        </div>
      )}
    </>
  );
}

export default AnalyticsBox;
