const fireDetectionData = () => {
  const dataArr = [
    {
      city: "Dilolo",
      lat: -10.9282,
      long: 22.2698,
      models: [
        {
          title: "fire_detection",
          timestamp_dict: [
            {
              "2024-09-04": {
                min: 0.0,
                max: 5000.0,
                mean: 0.0,
                std_dev: 0.0,
                palette: ["None"],
                unit: "None",
                no_data_value: "no",
                style_id: "none",
                timestamp_id: "2a1a2a91-27ae-4e32-a4f1-7a26cb3b1623",
                layer_id: "fa9583f7-f5cc-4044-b839-1228adc5ab32",
                timestamp_id_input: "97d7e226-67db-4a2b-b423-022c288237cb",
                layer_id_input: "78f6b3c0-e53f-4597-a83c-f51faabfbfc9",
                transition_points: "None",
                normalize: false,
              },
              geojson_data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.18438103158501, -10.891551797084409],
                    },
                    id: "+123478-60622",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.194046904042132, -10.913542555239655],
                    },
                    id: "+123532-60745",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.19918975904372, -10.966004167832235],
                    },
                    id: "+123561-61036",
                    properties: {
                      count: 8,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.213055255454105, -10.996798415771853],
                    },
                    id: "+123638-61208",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.27248779465145, -10.846671965489797],
                    },
                    id: "+123968-60372",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.28465697236699, -11.008704087670715],
                    },
                    id: "+124035-61274",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.28696034489037, -10.925980766865925],
                    },
                    id: "+124045-60813",
                    properties: {
                      count: 13,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.28813045813225, -10.925699755418071],
                    },
                    id: "+124055-60812",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.320170369932516, -10.874825166494103],
                    },
                    id: "+124233-60529",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.327985712904354, -10.860721616533427],
                    },
                    id: "+124277-60451",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.33328577308066, -10.893959282045849],
                    },
                    id: "+124306-60636",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.3358459716404, -10.871995473349127],
                    },
                    id: "+124321-60513",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.269865396438917, -10.928185404417858],
                    },
                    id: "+124434-60351",
                    properties: {
                      count: 916722,
                      fire_vec: 0,
                    },
                  },
                ],
              },
            },
            {
              "2024-08-30": {
                min: 0.0,
                max: 5000.0,
                mean: 0.0,
                std_dev: 0.0,
                palette: ["None"],
                unit: "None",
                no_data_value: "no",
                style_id: "none",
                timestamp_id: "9e51b625-7edd-40fb-b0ab-7a5775eeb26c",
                layer_id: "fa9583f7-f5cc-4044-b839-1228adc5ab32",
                timestamp_id_input: "a751515e-8698-4324-b020-c443a25e5417",
                layer_id_input: "78f6b3c0-e53f-4597-a83c-f51faabfbfc9",
                transition_points: "None",
                normalize: false,
              },
              geojson_data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.190363811377242, -10.882999835579591],
                    },
                    id: "+123511-60575",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.217200980490315, -11.00820252830375],
                    },
                    id: "+123661-61271",
                    properties: {
                      count: 8,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.218481079770186, -11.00813515465744],
                    },
                    id: "+123667-61271",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.265373137601223, -10.842755310851036],
                    },
                    id: "+123928-60351",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.26609178982852, -10.84676778578677],
                    },
                    id: "+123933-60373",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.26618162135693, -10.84571975128863],
                    },
                    id: "+123934-60367",
                    properties: {
                      count: 8,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.266810442055814, -10.845989245873866],
                    },
                    id: "+123936-60369",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.267169768169463, -10.846887561157986],
                    },
                    id: "+123938-60374",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.26842740956723, -10.847246887271634],
                    },
                    id: "+123945-60376",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.268607072624054, -10.847696044913693],
                    },
                    id: "+123946-60378",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.26920594948013, -10.84790565181332],
                    },
                    id: "+123949-60379",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.27541630247768, -10.86284164060395],
                    },
                    id: "+123984-60461",
                    properties: {
                      count: 10,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.329243354302122, -10.895306754972028],
                    },
                    id: "+124284-60643",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.355114834484763, -10.910667946330472],
                    },
                    id: "+124428-60728",
                    properties: {
                      count: 6,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35538432907, -10.910039125631588],
                    },
                    id: "+124429-60725",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.269864457086495, -10.928187114968232],
                    },
                    id: "+124434-60351",
                    properties: {
                      count: 916722,
                      fire_vec: 0,
                    },
                  },
                ],
              },
            },
            {
              "2024-08-25": {
                min: 0.0,
                max: 5000.0,
                mean: 0.0,
                std_dev: 0.0,
                palette: ["None"],
                unit: "None",
                no_data_value: "no",
                style_id: "none",
                timestamp_id: "8e4582c2-5f66-406a-8fc6-114c4b979210",
                layer_id: "fa9583f7-f5cc-4044-b839-1228adc5ab32",
                timestamp_id_input: "7c0d226b-5979-474f-ab81-d35dacb6d66a",
                layer_id_input: "78f6b3c0-e53f-4597-a83c-f51faabfbfc9",
                transition_points: "None",
                normalize: false,
              },
              geojson_data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.19242993653072, -10.9634888850367],
                    },
                    id: "+123522-61023",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.238693173662874, -10.939234372365473],
                    },
                    id: "+123780-60888",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.278308877692545, -10.982263674474797],
                    },
                    id: "+124000-61127",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.281672569367526, -10.988192555349986],
                    },
                    id: "+124021-61159",
                    properties: {
                      count: 18,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.28235129647108, -10.988641712992047],
                    },
                    id: "+124023-61163",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.28456115207002, -10.987455936817009],
                    },
                    id: "+124036-61156",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.30337187411948, -10.849133349368286],
                    },
                    id: "+124141-60386",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.30412047018958, -10.848085314870145],
                    },
                    id: "+124144-60380",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.30450375137747, -10.846815695935256],
                    },
                    id: "+124146-60373",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.304659459360053, -10.84790565181332],
                    },
                    id: "+124147-60379",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.34298757814915, -10.928185094370802],
                    },
                    id: "+124360-60826",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.345263310202252, -10.93019133183867],
                    },
                    id: "+124373-60837",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.345682524001507, -10.855870713999181],
                    },
                    id: "+124375-60424",
                    properties: {
                      count: 1,
                      fire_vec: 0,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.345754389224236, -10.856026421981761],
                    },
                    id: "+124376-60423",
                    properties: {
                      count: 15,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.353156507165384, -10.938443854915448],
                    },
                    id: "+124415-60882",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35412668767223, -10.933035996905048],
                    },
                    id: "+124422-60852",
                    properties: {
                      count: 6,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35403685614382, -10.935623144923312],
                    },
                    id: "+124422-60866",
                    properties: {
                      count: 10,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.353774271676155, -10.937106056153866],
                    },
                    id: "+124422-60872",
                    properties: {
                      count: 26,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.354396182257467, -10.928185094370802],
                    },
                    id: "+124424-60826",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.354845339899526, -10.928454588956038],
                    },
                    id: "+124426-60828",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.354755508371117, -10.932317344677752],
                    },
                    id: "+124426-60849",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.354737542065433, -10.933089895822096],
                    },
                    id: "+124426-60853",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.355833486712058, -10.930251219524278],
                    },
                    id: "+124431-60837",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35563585734955, -10.932550906651624],
                    },
                    id: "+124431-60850",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35560890789103, -10.935865690050024],
                    },
                    id: "+124431-60869",
                    properties: {
                      count: 4,
                      fire_vec: 0,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.355893374397667, -10.927226891401075],
                    },
                    id: "+124432-60820",
                    properties: {
                      count: 6,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.355803542869253, -10.934563132888051],
                    },
                    id: "+124432-60861",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.355653823655235, -10.93575489783165],
                    },
                    id: "+124433-60865",
                    properties: {
                      count: 30,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.269850147197246, -10.928184948143915],
                    },
                    id: "+124434-60351",
                    properties: {
                      count: 916580,
                      fire_vec: 0,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35628264435412, -10.930700377166337],
                    },
                    id: "+124434-60840",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.356077315146322, -10.931457528620095],
                    },
                    id: "+124434-60844",
                    properties: {
                      count: 7,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.35628264435412, -10.934203806774404],
                    },
                    id: "+124434-60860",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.356154313599244, -10.936847420324812],
                    },
                    id: "+124434-60873",
                    properties: {
                      count: 7,
                      fire_vec: 1,
                    },
                  },
                ],
              },
            },
            {
              "2024-08-20": {
                min: 0.0,
                max: 5000.0,
                mean: 0.0,
                std_dev: 0.0,
                palette: ["None"],
                unit: "None",
                no_data_value: "no",
                style_id: "none",
                timestamp_id: "072d56fb-6841-4211-970a-b981b09504d1",
                layer_id: "fa9583f7-f5cc-4044-b839-1228adc5ab32",
                timestamp_id_input: "fd59a169-4400-4496-b516-5e8bf0f19658",
                layer_id_input: "78f6b3c0-e53f-4597-a83c-f51faabfbfc9",
                transition_points: "None",
                normalize: false,
              },
              geojson_data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.185782403428234, -10.916866321790897],
                    },
                    id: "+123485-60763",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.18649440146824, -10.917568338549968],
                    },
                    id: "+123487-60762",
                    properties: {
                      count: 27,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.18614172954188, -10.918034131660253],
                    },
                    id: "+123487-60770",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.219379395054304, -10.871501399942861],
                    },
                    id: "+123672-60511",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.236043143574722, -10.957065930755245],
                    },
                    id: "+123765-60986",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.236806711566224, -10.955404047479623],
                    },
                    id: "+123769-60978",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.237255869208283, -10.955044721365976],
                    },
                    id: "+123772-60976",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.237037706924994, -10.956559024273492],
                    },
                    id: "+123772-60984",
                    properties: {
                      count: 7,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.23774994261455, -10.9552243844228],
                    },
                    id: "+123774-60976",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.238064352963992, -10.95612269970692],
                    },
                    id: "+123776-60981",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.2524373975099, -10.96492618949129],
                    },
                    id: "+123856-61031",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.262139202578393, -10.977412771940552],
                    },
                    id: "+123910-61100",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.2915141123691, -10.952026382011335],
                    },
                    id: "+124075-60958",
                    properties: {
                      count: 10,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.29260207199098, -10.95642213813496],
                    },
                    id: "+124080-60982",
                    properties: {
                      count: 9,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.293849732107812, -10.95612269970692],
                    },
                    id: "+124087-60982",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.304449852460422, -10.994929919980883],
                    },
                    id: "+124146-61198",
                    properties: {
                      count: 2,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.304539683988835, -10.995289246094531],
                    },
                    id: "+124146-61200",
                    properties: {
                      count: 1,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.32063749388026, -10.856014444444641],
                    },
                    id: "+124236-60424",
                    properties: {
                      count: 5,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.333225885395052, -10.894168888945476],
                    },
                    id: "+124306-60635",
                    properties: {
                      count: 6,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.354890255663733, -10.843069721200479],
                    },
                    id: "+124427-60352",
                    properties: {
                      count: 4,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.355444216755608, -10.843414075392724],
                    },
                    id: "+124429-60354",
                    properties: {
                      count: 3,
                      fire_vec: 1,
                    },
                  },
                  {
                    type: "Feature",
                    geometry: {
                      geodesic: false,
                      type: "Point",
                      coordinates: [22.269866317248038, -10.928185164830438],
                    },
                    id: "+124434-60351",
                    properties: {
                      count: 916679,
                      fire_vec: 0,
                    },
                  },
                ],
              },
            },
          ],
          timestamp: ["2024-09-04", "2024-08-30", "2024-08-25", "2024-08-20"],
        },
      ],
    },
  ];
  return dataArr;
};

export default fireDetectionData;
