import React, { useEffect, useState, useRef } from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import { MapContainer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import fireDetectionData from "../MapDisplay/fireDetectionData";
import { getClusteringCoord } from "../../utils/apicalls";
import L from "leaflet";

const customIcon = new L.Icon({
  iconUrl: require("../../svg_icon.svg").default,
  iconSize: new L.Point(40, 47),
});

const MapDisplayFire = ({
  lat,
  lon,
  loading,
  setLoading,
  selectedDatasetDetails,
  enableLayerChangeOpt,
  selectedModelName,
  setShowPopUp,
  showPopUp,
  setShowDirection,
  isAnalyticsLayer,
  setIsAnalyticsLayer,
  isSateliteLayer,
  setIsSateliteLayer,
  selectedTimeStamp,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldFlyTo, setShouldFlyTo] = useState(false);
  const [clusteringCoord, setClusteringCoord] = useState([]);
  const timeoutRef = useRef(null);

  function FlytoPosition({ val }) {
    const map = useMap();
    map.flyTo(val, 12);
    setShouldFlyTo(false);
  }

  useEffect(() => {
    if (selectedModelName == "fire_detection") {
      const fetchAnalyticsDetails = async (jsonData, selectedTimeStamp) => {
        try {
          const jsonData1 = await getClusteringCoord(
            jsonData,
            selectedTimeStamp
          );
          setClusteringCoord(jsonData1);
        } catch (error) {
          console.log(error);
          // setLoading(false);
        }
      };
      const jsonData = fireDetectionData();
      fetchAnalyticsDetails(jsonData, selectedTimeStamp);
    }
  }, [selectedModelName, selectedTimeStamp]);

  useEffect(() => {
    // if (loading) {
    //   setTimeout(() => setLoading(false), 2000);
    // }
    setShouldFlyTo(true);
    setIsAnalyticsLayer(true);
    setIsSateliteLayer(false);
  }, [lat, lon]);
  return (
    <MapContainer
      style={{ height: "500px" }}
      center={[lat, lon]}
      zoom={4}
      scrollWheelZoom={true}
      maxZoom={18}
    >
      <MarkerClusterGroup chunkedLoading>
        {clusteringCoord.map((address, index) => (
          <Marker
            key={index}
            position={[address[0], address[1]]}
            title={address[2]}
            icon={customIcon}
          ></Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default MapDisplayFire;
