import React, { useState, useEffect } from "react";
import "./InfoCardPanel.css";
import InfoCards from "../InfoCards/InfoCards";

import { loadJSON, getCityData } from "../../utils/apicalls";
import data from "./data";

const InfoCardPanel = ({
  city,
  onCardClick,
  upcomingPassDetails,
  filteredInfoCards,
  modelTitle,
}) => {
  // const [filteredInfoCards, setFilteredInfoCards] = useState([]);
  // const [cityLat, setCityLat] = useState("");
  // const [cityLong, setCityLong] = useState("");
  const [loading, setLoading] = useState(true);
  const [openNav, setOpenNav] = useState(true);
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    if (filteredInfoCards.length > 0) {
      setLoading(false);
      const selectedCardData = filteredInfoCards.find((e) => e.city == city);
      if (selectedCardData) setSelectedModel(selectedCardData);
    }
  }, [filteredInfoCards]);

  useEffect(() => {
    const selectedCardData = filteredInfoCards.find((e) => e.city == city);
    if (selectedCardData) setSelectedModel(selectedCardData);
    console.log(selectedCardData);
  }, [city]);

  // useEffect(() => {
  //   const cities = ["Jeddah", "Malta", "Dilolo"];
  //   const models_names = [];
  //   const fetchCityDataInfo = async (city) => {
  //     try {
  //       const jsonData1 = await loadJSON(
  //         `https://apidcit.skyserve.ai/v1/default/?city_name=${city}`
  //       );
  //       // const jsonData1 = await loadJSON("/data/api.json");
  //       const cityData = await getCityData(jsonData1, city);
  //       setCityLat(cityData.lat);
  //       setCityLong(cityData.lon);
  //       const trimmedInfoCards = cityData.models;
  //       const ans = trimmedInfoCards.filter((e) => {
  //         return e.timestamp_dict.length > 0;
  //       });
  //       models_names.push({
  //         key: ans[0].title,
  //         value: ans,
  //         city: cityData.city,
  //         timer: {},
  //       });
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };

  //   const fetchAllCitiesData = async () => {
  //     await Promise.all(cities.map((city) => fetchCityDataInfo(city)));
  //     const dataSet = [];
  //     models_names.forEach((model) => {
  //       dataSet.push({ models: model.value, city: model.city }); // Use spread operator to push each item in model.value into dataSet
  //     });
  //     setFilteredInfoCards(dataSet);
  //     setLoading(false);
  //   };

  //   fetchAllCitiesData();
  // }, []);

  // useEffect(() => {
  //   filteredInfoCards.forEach((model) => {
  //     const matchingPass = upcomingPassDetails.find(
  //       (pass) => pass.city == model.city
  //     );
  //     console.log(model, "matching");
  //     if (matchingPass) {
  //       model.timer = matchingPass.timer;
  //     }
  //   });
  // }, [upcomingPassDetails]);

  if (loading) {
    return <div>Loading City InfoPanel...</div>;
  }
  return (
    <div
      className={`info-card-panel`}
      onMouseEnter={() => setOpenNav(true)}
      onMouseLeave={() => setOpenNav(false)}
    >
      <div className="logos">
        <img
          src="/Images/skyserveowl.png"
          // onClick={() => setOpenNav(!openNav)}
        ></img>
        {/* {openNav && <img src="/Images/earthsights_logo.png"></img>} */}
      </div>
      {filteredInfoCards.map((card, index) => (
        <InfoCards
          card={card.models[0]}
          cardCity={card.city}
          city={city}
          key={index}
          onClick={() =>
            onCardClick(card.models[0], card.lat, card.lng, card.city)
          }
          upcomingPassDetails={upcomingPassDetails}
          openNav={openNav}
          selectedModel={selectedModel}
        />
      ))}
    </div>
  );
};

export default InfoCardPanel;
