import React, { useState, useEffect } from "react";
import { BsX, BsXCircle } from "react-icons/bs";
import "./LayerBox.css";

function LayerBox({ isSateliteLayer, setIsSateliteLayer }) {
  return (
    <>
      <div className="layer-box">
        <div
          className={`layer-box-heading ${
            isSateliteLayer ? "change-color" : ""
          }`}
        >
          <span className="material-symbols-outlined">layers</span>
          <h5> Layer</h5>
        </div>
        <div>
          <div
            className="satelite-layer-switch"
            onClick={() => setIsSateliteLayer(!isSateliteLayer)}
          >
            <p className={`${isSateliteLayer && "change-color"}`}>Satellite</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LayerBox;
