const analyticsData = () => {
  const dataArr = [
    {
      city: "Bengaluru",
      models: {
        boxTitle: "Built-up area",
        title: "building_detection",
        timestamp_dict: [
          {
            "2024-03-28": {
              downOrUp: "down",
              active_fires: "35.235km2",
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "down",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "down",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-04-02": {
              downOrUp: "up",
              active_fires: "35.235km2",
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-04-07": {
              downOrUp: "up",
              active_fires: "35.235km2",
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-04-27": {
              downOrUp: "up",
              active_fires: "35.235km2",
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-05-02": {
              downOrUp: "down",
              active_fires: "35.235km2",
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "down",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "down",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-05-07": {
              downOrUp: "down",
              active_fires: "35.235km2",
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "down",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "down",
                  active: "6",
                },
              ],
            },
          },
        ],
      },
    },
    {
      city: "Malta",
      models: {
        boxTitle: "Vessels Detected",
        title: "ship_detection",
        timestamp_dict: [
          {
            "2024-08-14": {
              downOrUp: "up",
              active_fires: 124,
              table_details: [
                {
                  time: "recent",
                  area: "202",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "60",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "120",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-08-21": {
              downOrUp: "up",
              active_fires: 124,
              table_details: [
                {
                  time: "recent",
                  area: "202",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "60",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "120",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-08-24": {
              downOrUp: "up",
              active_fires: 124,
              table_details: [
                {
                  time: "recent",
                  area: "202",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "60",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "120",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-08-29": {
              downOrUp: "up",
              active_fires: 124,
              table_details: [
                {
                  time: "recent",
                  area: "202",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "60",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "120",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
        ],
      },
    },
    {
      city: "Dilolo",
      models: {
        boxTitle: "Active fires",
        title: "fire_detection",
        timestamp_dict: [
          {
            "2024-08-20": {
              downOrUp: "up",
              active_fires: 6,
              table_details: [
                {
                  time: "recent",
                  area: "5.3272km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "14.098km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "33",
                },
              ],
            },
          },
          {
            "2024-08-25": {
              downOrUp: "up",
              active_fires: 0,
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "14.098km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "33",
                },
                {
                  time: "10 days ago",
                  area: "51.192km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  active: "11",
                },
              ],
            },
          },
          {
            "2024-08-30": {
              downOrUp: "up",
              active_fires: 33,
              table_details: [
                {
                  time: "recent",
                  area: "14.098km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "33",
                },
                {
                  time: "5 days ago",
                  area: "51.192km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  active: "11",
                },
                {
                  time: "10 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-09-04": {
              downOrUp: "up",
              active_fires: 22,
              table_details: [
                {
                  time: "recent",
                  area: "51.192km2",
                  stats: "263.12%",
                  downOrUp: "up",
                  active: "11",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
          {
            "2024-09-09": {
              downOrUp: "up",
              active_fires: 8,
              table_details: [
                {
                  time: "recent",
                  area: "97.56 km2",
                  stats: "90.51%",
                  downOrUp: "up",
                  active: "14",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
        ],
      },
    },
    {
      city: "Mahebourg",
      models: {
        boxTitle: "Slicks Detected",
        title: "oil_spills",
        timestamp_dict: [
          {
            "2020-12-08": {
              downOrUp: "up",
              active_fires: 0,
              table_details: [
                {
                  time: "recent",
                  area: "10.928km2",
                  stats: "105.14%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "5 days ago",
                  area: "5.3272km2",
                  stats: "95.45%",
                  downOrUp: "up",
                  active: "6",
                },
                {
                  time: "10 days ago",
                  area: "5.928km2",
                  stats: "0%",
                  downOrUp: "up",
                  active: "6",
                },
              ],
            },
          },
        ],
      },
    },
  ];
  return dataArr;
};

export default analyticsData;
